<template>
  <a-drawer
    width="55%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>发送数据</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <div class="form_item">
        <div>
          <a-form-model-item
            label="顶部显示文字"
            prop="topText"
          >
            <a-input
              v-model="form.topText"
              placeholder="请输入"
              :maxLength="30"
            />
          </a-form-model-item>
          <a-form-model-item
            label="顶部文字颜色"
            prop="topTextColourId"
          >
            <a-select
              v-model="form.topTextColourId"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in colorList"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="顶部文字模式"
            prop="topTextModel"
          >
            <a-select
              v-model="form.topTextModel"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in options"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="顶部文字速度"
            prop="topTextSpeed"
          >
            <a-select
              v-model="form.topTextSpeed"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in option"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="顶部文字停留时间S"
            prop="topTextWaitTime"
          >
            <a-input
              v-model="form.topTextWaitTime"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="对向已超速显示文字"
            prop="subtendThenSpeedText"
          >
            <a-input
              v-model="form.subtendThenSpeedText"
              placeholder="请输入"
              :maxLength="30"
            />
          </a-form-model-item>
          <a-form-model-item
            label="对向已超速文字颜色"
            prop="subtendThenSpeedTextColourId"
          >
            <a-select
              v-model="form.subtendThenSpeedTextColourId"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in colorList"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="对向已超速文字模式"
            prop="subtendThenSpeedTextModel"
          >
            <a-select
              v-model="form.subtendThenSpeedTextModel"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in options"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="对向已超速文字速度"
            prop="subtendThenSpeedTextSpeed"
          >
            <a-select
              v-model="form.subtendThenSpeedTextSpeed"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in option"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="对向已超速文字停留时间S"
            prop="subtendNotSpeedTextWaitTime"
          >
            <a-input
              v-model="form.subtendNotSpeedTextWaitTime"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="待机显示文字"
            prop="standbyText"
          >
            <a-input
              v-model="form.standbyText"
              placeholder="请输入"
              :maxLength="30"
            />
          </a-form-model-item>
          <a-form-model-item
            label="待机文字颜色"
            prop="standbyTextColourId"
          >
            <a-select
              v-model="form.standbyTextColourId"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in colorList"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="待机文字模式"
            prop="standbyTextModel"
          >
            <a-select
              v-model="form.standbyTextModel"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in options"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="待机文字速度"
            prop="standbyTextSpeed"
          >
            <a-select
              v-model="form.standbyTextSpeed"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in option"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="待机文字停留时间S"
            prop="standbyTextWaitTime"
          >
            <a-input
              v-model="form.standbyTextWaitTime"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="本地已超速显示文字"
            prop="localThenSpeedText"
          >
            <a-input
              v-model="form.localThenSpeedText"
              placeholder="请输入"
              :maxLength="30"
            />
          </a-form-model-item>
          <a-form-model-item
            label="本地已超速文字颜色"
            prop="localThenSpeedTextColourId"
          >
            <a-select
              v-model="form.localThenSpeedTextColourId"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in colorList"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="本地已超速文字模式"
            prop="localThenSpeedTextModel"
          >
            <a-select
              v-model="form.localThenSpeedTextModel"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in options"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="本地已超速文字速度"
            prop="localThenSpeedTextSpeed"
          >
            <a-select
              v-model="form.localThenSpeedTextSpeed"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in option"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="本地已超速文字停留时间S"
            prop="localThenSpeedTextWaitTime"
          >
            <a-input
              v-model="form.localThenSpeedTextWaitTime"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="本地未超速显示文字"
            prop="localNotSpeedText"
          >
            <a-input
              v-model="form.localNotSpeedText"
              placeholder="请输入"
              :maxLength="30"
            />
          </a-form-model-item>
          <a-form-model-item
            label="本地未超速文字颜色"
            prop="localNotSpeedTextColourId"
          >
            <a-select
              v-model="form.localNotSpeedTextColourId"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in colorList"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="本地未超速文字模式"
            prop="localNotSpeedTextModel"
          >
            <a-select
              v-model="form.localNotSpeedTextModel"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in options"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="本地未超速文字速度"
            prop="localNotSpeedTextSpeed"
          >
            <a-select
              v-model="form.localNotSpeedTextSpeed"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in option"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="本地未超速文字停留时间S"
            prop="localNotSpeedTextWaitTime"
          >
            <a-input
              v-model="form.localNotSpeedTextWaitTime"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
        </div>
      </div>
      <div class="form_item">
        <div>
          <a-form-model-item
            label="本地限速值km/h"
            prop="rateLimiting"
          >
            <a-input
              v-model="form.rateLimiting"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
          <a-form-model-item
            label="限速显示时长S"
            prop="rateShowDuration"
          >
            <a-input
              v-model="form.rateShowDuration"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
          <a-form-model-item
            label="是否息屏"
            prop="sfBreathingPlate"
          >
            <a-radio-group
              v-model="form.sfBreathingPlate"
              button-style="solid"
            >
              <a-radio-button :value="0">是</a-radio-button>
              <a-radio-button :value="1">否</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="速度临界值km/h"
            prop="rateCritical"
          >
            <a-input
              v-model="form.rateCritical"
              placeholder="请输入"
              :maxLength="30"
              oninput="value=value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="待机显示时长"
            prop="standbyTime"
          >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              v-model="form.standbyTime"
              format="HH:mm"
            />
          </a-form-model-item>
          <a-form-model-item
            label="本地音频时长"
            prop="audioDuration"
          >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.audioDuration"
            />
          </a-form-model-item>
          <a-form-model-item
            label="无线音频时长"
            prop="wirelessAudioDuration"
          >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.wirelessAudioDuration"
            />
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="本地数据显示时间"
            prop="localShowDuration"
          >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.localShowDuration"
            />
          </a-form-model-item>
          <a-form-model-item
            label="对向数据显示时间"
            prop="subtendShowDuration"
          >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.subtendShowDuration"
            />
          </a-form-model-item>
          <a-form-model-item
            label="对向有效显示时间"
            prop="subtendValidDuration"
          >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.subtendValidDuration"
            />
          </a-form-model-item>
          <!-- <a-form-model-item
            label="屏幕亮度"
            prop="localNotSpeedTextWaitTime"
          >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
            />
          </a-form-model-item> -->
        </div>
        <div>
          <a-form-model-item
            label="本地有车语音开关"
            prop="localHaveAudioSatus"
          >
            <a-radio-group
              v-model="form.localHaveAudioSatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="有车超音速开关"
            prop="speedHaveAudioSatus"
          >
            <a-radio-group
              v-model="form.speedHaveAudioSatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="对向音频开关"
            prop="subtendHaveAudioSatus"
          >
            <a-radio-group
              v-model="form.subtendHaveAudioSatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>

          <a-form-model-item
            label="对向来车警告"
            prop="subtendHaveWarningStatus"
          >
            <a-radio-group
              v-model="form.subtendHaveWarningStatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="MP3播放音量"
            prop="mp3Volume"
          >
            <a-select
              v-model="form.mp3Volume"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in optionVolume"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="控制器的时间"
            prop="directorTime"
          >
            <a-input
              v-model="form.directorTime"
              placeholder="请输入"
              v-show="false"
            />
            <a-button
              type="primary"
              @click="handleShi"
            >
              点击获取校时
            </a-button>
          </a-form-model-item>
          <a-form-model-item
            label="输出模式"
            prop="outPutPattern"
          >
            <a-radio-group
              v-model="form.outPutPattern"
              button-style="solid"
            >
              <a-radio-button :value="0">自动</a-radio-button>
              <a-radio-button :value="1">手动</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="负载状态"
            prop="loadSatus"
          >
            <a-radio-group
              v-model="form.loadSatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </div>

      </div>
      <div class="form_item">
        <div>
          <a-form-model-item
            label="工作时段1"
            prop="workingHours1"
          >
            <a-input
              v-model="form.workingHours1"
              placeholder="请输入"
              v-show="false"
            />
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              @change="handleChange"
              v-model="form.workingHours11"
            />至
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.workingHours12"
            />
          </a-form-model-item>
          <a-form-model-item
            label="屏幕亮度"
            prop="screenLuminance1"
          >
            <a-select
              v-model="form.screenLuminance1"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in optionLing"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="语音音量"
            prop="voiceVolume1"
          >
            <a-select
              v-model="form.voiceVolume1"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in optionsYu"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="红外预警状态"
            prop="infraredStatus"
          >
            <a-radio-group
              v-model="form.infraredStatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="工作时段2"
            prop="workingHours2"
          >
            <input
              type="text"
              v-model="form.workingHours2"
              v-show="false"
            >
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.workingHours21"
            />至
            <a-time-picker
              :default-value="moment('12:08', 'HH:mm')"
              format="HH:mm"
              v-model="form.workingHours22"
            />
          </a-form-model-item>
          <a-form-model-item
            label="屏幕亮度"
            prop="screenLuminance2"
          >
            <a-select
              v-model="form.screenLuminance2"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in optionLing"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="语音音量"
            prop="voiceVolume2"
          >
            <a-select
              v-model="form.voiceVolume2"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in optionsYu"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="碰撞预警状态"
            prop="crashStatus"
          >
            <a-radio-group
              v-model="form.crashStatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="太阳能工作模式"
            prop="solarEnergyModel"
          >
            <a-radio-group
              v-model="form.solarEnergyModel"
              button-style="solid"
            >
              <a-radio-button :value="0">正常</a-radio-button>
              <a-radio-button :value="1">节能</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="光伏状态"
            prop="localNotSpeedTextSpeed"
          >
            <a-radio-group
              v-model="form.localNotSpeedTextSpeed"
              button-style="solid"
            >
              <a-radio-button :value="0">白天</a-radio-button>
              <a-radio-button :value="1">夜晚</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="预警状态"
            prop="earlyStatus"
          >
            <a-radio-group
              v-model="form.earlyStatus"
              button-style="solid"
            >
              <a-radio-button :value="0">复位</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="辅助预警状态"
            prop="assistStatus"
          >
            <a-radio-group
              v-model="form.assistStatus"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </div>
        <div>
          <a-form-model-item
            label="待机音调"
            prop="standbyTone"
          >
            <a-select
              v-model="form.standbyTone"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in optionsDiao"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="报警音调"
            prop="giveAlarmTone"
          >
            <a-select
              v-model="form.giveAlarmTone"
              placeholder="请选择"
              style="width: 100%"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              allow-clear
            >
              <a-select-option
                v-for="(item, key) in optionsDiao"
                :key="key"
                :value="item.id"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="预警车道选择"
            prop="earlyLane"
          >
            <a-radio-group
              v-model="form.earlyLane"
              button-style="solid"
            >
              <a-radio-button :value="0">左</a-radio-button>
              <a-radio-button :value="1">右</a-radio-button>
              <a-radio-button :value="2">全车道</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="辅助激光状态"
            prop="assistLaser"
          >
            <a-radio-group
              v-model="form.assistLaser"
              button-style="solid"
            >
              <a-radio-button :value="0">开</a-radio-button>
              <a-radio-button :value="1">关</a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </div>
      <div class="bottom-control">
        <a-space>
          <a-button
            type="primary"
            :loading="submitLoading"
            @click="submitForm"
          >
            保存
          </a-button>
          <a-button
            type="dashed"
            @click="cancel"
          >
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <!-- <CreateType
      ref="createType"
      @ok="getType"
    ></CreateType> -->
  </a-drawer>
</template>

<script>
import { getDevice, addDevice, updateDevice } from '@/api/project/device'
import { listDeviceType } from '@/api/project/deviceType'
import { listColor } from '@/api/project/deviceColor'
import moment from 'moment'
import addMap from '@/components/AMap/index.vue'
import upImgModal from '@/components/upImgModal'
import store from '@/store'
import CreateType from '../../deviceType/modules/CreateForm.vue'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    addMap,
    upImgModal,
    CreateType
  },
  data () {
    return {
      previewVisible: false,
      optionVolume: [],
      optionLing: [],
      optionsYu: [{ id: 0, name: '无' }, { id: 1, name: '低' }, { id: 2, name: '中' }, { id: 3, name: '高' }],
      optionsDiao: [{ id: 0, name: 'Wail' }, { id: 1, name: 'Yelp' }, { id: 2, name: 'Hi-lo' }, { id: 3, name: 'Hyper-Yelp' }, { id: 4, name: 'Hyper-Lo' }, { id: 3, name: 'Whoop' }],
      fileList: [
      ],
      agentList: [],
      submitLoading: false,
      header: { Authorization: 'Bearer ' + store.getters.access_token },
      formTitle: '',
      fileName: '',
      // 表单参数
      form: {
        id: 0,
        devName: '',
        devCode: '',
        devImgUrl: '',
        patternType: 0,
        devTypeId: undefined,
        addressName: '',
        log: '',
        lat: '',
        cityCode: '',
        provinceCode: '',
        areaCode: '',
        mp3AudioUrl: '',
        expirationTimes: ''
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      options: [{ id: 0, name: '静止' }, { id: 1, name: '左移' }, { id: 2, name: '右移' }, { id: 3, name: '上移' }, { id: 4, name: '下移' }, { id: 5, name: '闪烁' }],
      option: [{ id: 1, name: '一级' }, { id: 2, name: '二级' }, { id: 3, name: '三级' }, { id: 4, name: '四级' }, { id: 5, name: '五级' }, { id: 5, name: '五级' }, { id: 6, name: '六级' }, { id: 7, name: '七级' }, { id: 8, name: '八级' }, { id: 9, name: '九级' }, { id: 10, name: '十级' }],
      uploadLoading: false,
      rules: {
        addressName: [
          { required: true, message: '请在地图上下选择地址', trigger: 'blur' }
        ],
        devCode: [
          { required: true, message: '请输入设备序列号', trigger: 'blur' }
        ],
        devName: [
          { required: true, message: '请输入设备名称', trigger: 'blur' }
        ],
        devImgUrl: [
          { required: true, message: '请上传设备图片', trigger: 'blur' }
        ],
        devTypeId: [
          { required: true, message: '请选择设备类型', trigger: 'blur' }
        ]
      },
      colorList: []
    }
  },
  filters: {
  },
  created () {
    this.getColor()
    const arr = []
    const array = []
    for (let i = 0; i < 20; i++) {
      console.log(arr[i])
      const k = i * 5
      arr.push({ id: k, name: k })
    }
    for (let i = 0; i < 11; i++) {
      console.log(arr[i])
      const k = i * 10
      array.push({ id: k, name: k + '%' })
    }
    this.optionVolume = arr
    this.optionLing = array
  },
  computed: {
  },
  watch: {
  },
  methods: {
    moment,
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleChange (e) {
      console.log(e, 'ddddd大大大大大')
    },
    // 点击获取校时
    handleShi () {
      this.form.directorTime = moment().format('YYYY-MM-DD HH:mm:ss')
      this.$message.success('获取成功')
      this.$forceUpdate()
    },
    // 查询所有的设备类型
    getColor () {
      listColor().then(res => {
        this.colorList = res.data
      })
    },
    onChange (e, data) {
      console.log(e, data)
      this.form.produceDate = data
    },
    onClose () {
      this.open = false
    },
    // 点击选择地址
    handleAddress (e) {
      console.log(e, 'mmmm')
      this.form.addressName = e.detailedAddress
      this.showId = false
      this.form.log = e.lng
      this.form.lat = e.lat
      this.form.cityCode = e.info.city ? e.info.city : e.info.province
      this.form.provinceCode = e.info.province
      this.form.areaCode = e.info.district
      this.$refs['form'].clearValidate(['address'])
      this.$forceUpdate()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: 0,
        devName: '',
        devCode: '',
        devImgUrl: '',
        patternType: 0,
        devTypeId: undefined,
        addressName: '',
        log: '',
        lat: '',
        cityCode: '',
        provinceCode: '',
        areaCode: '',
        mp3AudioUrl: '',
        expirationTimes: ''
      }
      this.fileName = ''
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const id = row ? row.id : ids
      getDevice({ 'id': id }).then(response => {
        const num = { ...response.data, workingHours11: '', workingHours12: '' }
        console.log(num.workingHours1, 'workingHours1workingHours1')
        if (num.workingHours1) {
          const index = num.workingHours1.indexOf('至')
          num.workingHours11 = moment(num.workingHours1.substring(0, index), 'HH:mm')
          num.workingHours12 = moment(num.workingHours1.substring(index + 1), 'HH:mm')
          console.log(num.workingHours11, num.workingHours12, 'workingHours12workingHours12workingHours12')
        }
        if (num.workingHours2) {
          const indexs = num.workingHours2.indexOf('至')
          num.workingHours21 = moment(num.workingHours2.substring(0, indexs), 'HH:mm')
          num.workingHours22 = moment(num.workingHours2.substring(indexs + 1), 'HH:mm')
        }
        // 待机时间
        if (num.standbyTime) {
          num.standbyTime = moment(num.standbyTime, 'HH:mm')
        }
        // 本地数据显示时间
        if (num.localShowDuration) {
          num.localShowDuration = moment(num.localShowDuration, 'HH:mm')
        }
        // 本地音频时长
        if (num.audioDuration) {
          num.audioDuration = moment(num.audioDuration, 'HH:mm')
        }
        // 无线音频时长
        if (num.wirelessAudioDuration) {
          num.wirelessAudioDuration = moment(num.wirelessAudioDuration, 'HH:mm')
        }
        // 对向数据显示时间
        if (num.subtendShowDuration) {
          num.subtendShowDuration = moment(num.subtendShowDuration, 'HH:mm')
        }
        // 对向有效显示时间
        if (num.subtendValidDuration) {
          num.subtendValidDuration = moment(num.subtendValidDuration, 'HH:mm')
        }
        this.form = num
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateDevice(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDevice(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-preview {
  position: relative;
  width: 100px;
  height: 100px;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
}

.slect_icon {
  display: flex;
  align-items: center;

  .icon_cir {
    margin-left: 30px;
    font-size: 25px;
    cursor: pointer;
  }
}

.form_item {
  display: flex;
  // align-items: center;
  justify-content: space-between;
}

// /deep/.ant-time-picker-input {
//   width: 100px !important;
// }
</style>
